import { t } from "i18next";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormWrapper from "@app/components/FormWrapper";
import BranchForm from "@app/components/forms/Branch.form";
import { RoutePaths } from "@app/routes/index";
import { createBranch, updateBranch } from "@app/services/Endpoints/Branches.API";
import {
    getCountries,
    getCountryCities,
    getRegionCities,
    getRegions,
} from "@app/services/Endpoints/Country.API";
import { getLicensee } from "@app/services/Endpoints/Licensee.API";
import { getRoles, getUserDetails } from "@app/services/Endpoints/User.API";
import { Branch } from "@app/types/Branch.types";
import { BranchErrors, ModalType, UserErrors } from "@app/types/Common.types";
import { City, Country, Region } from "@app/types/Countries.types";
import { Licensee } from "@app/types/Licensee";
import { Role, User, UserLevel } from "@app/types/User.type";
import { FormContext } from "@app/utils/contexts/Form.context";
import { isEmail } from "@app/utils/inputValidations";
import { getSecondsInTime, getTimeInSeconds } from "@app/utils/timeHelpers";

interface BranchContainerProps {
    branch?: Branch;
}

const initialFormData = {
    id: "",
    name: "",
    code: "",
    branchType: 0,
    countryID: "",
    regionID: "",
    cityID: "",
    address: "",
    pinCode: "",
    licenseeID: "",
    is24HourOpen: false,
    startTime: "00:00:00",
    endTime: "00:00:00",
    isActive: true,
    users: [
        {
            id: "",
            name: "",
            phoneNumber: "",
            roleId: "",
        },
    ],
};

const BranchContainer: FC<BranchContainerProps> = ({ branch }) => {
    const navigate = useNavigate();

    const { isDirty, triggerShowModal, setActions, setIsDirty } = useContext(FormContext);

    const [branchData, setBranchData] = useState<Branch>(initialFormData);
    const [errors, setErrors] = useState<Partial<BranchErrors>>();

    const [countries, setCountries] = useState<Country[]>([]);
    const [regions, setRegions] = useState<Region[]>([]);
    const [cities, setCities] = useState<City[]>([]);
    const [featchingUser, setFetchingUser] = useState<boolean>(false);
    const [licensees, setLicensees] = useState<Licensee[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [,] = useState(false);
    // const [passKeyModalObj, setPasseKeyModalObj] = useState({ email: "", passKey: "" });
    // const [openLicenseModal, setOpenLicenseModal] = useState<boolean>(false);

    const fetchCountries = useCallback(() => {
        if (!branchData.countryID && !countries?.length) {
            getCountries()
                .then((res) => setCountries([...res.data.data]))
                .catch(() => setCountries([]));
        }
    }, [branchData, countries]);

    const fetchRegions = useCallback(() => {
        getRegions(branchData.countryID)
            .then((res) => setRegions([...res.data.data]))
            .catch(() => setRegions([]));
    }, [branchData]);

    const fetchCities = useCallback(() => {
        if (branchData.regionID && branchData.countryID) {
            getRegionCities(branchData.countryID, branchData.regionID)
                .then((res) => setCities([...res.data.data]))
                .catch(() => setCities([]));
        }
        if (!branchData.regionID && branchData.countryID) {
            getCountryCities(branchData.countryID)
                .then((res) => setCities([...res.data.data]))
                .catch(() => setCities([]));
        }
    }, [branchData]);

    const fetchLincensees = useCallback(() => {
        getLicensee(undefined, undefined, undefined, undefined, undefined, [branchData.countryID])
            .then((res) => {
                setLicensees([...res.data.data]);
                setErrors({
                    ...errors,
                    countryID: res?.data?.data?.length
                        ? ""
                        : "No Licensee associated with this country",
                });
            })
            .catch(() => {
                setLicensees([]);
            });
    }, [branchData, errors]);

    const fetchUserRoles = useCallback(() => {
        if (!roles.length) {
            getRoles(UserLevel.Branch)
                .then((res) => setRoles([...res.data.data]))
                .catch(() => setRoles([]));
        }
    }, [roles]);

    useEffect(() => {
        if (
            !branchData.name &&
            branchData.users.length &&
            (branchData.users[0].id || branchData.users[0].roleId)
        ) {
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        if (branch) {
            setBranchData({
                ...branch,
                startTime: getSecondsInTime(Number(branch?.startTime)),
                endTime: getSecondsInTime(Number(branch?.endTime)),
            });
        }
    }, [branch]);

    useEffect(() => {
        fetchCountries();
        if (branchData.countryID && !regions.length && !errors?.countryID) fetchRegions();
        if ((branchData.countryID || branchData.regionID) && !cities.length && !errors?.countryID)
            fetchCities();
        if (!licensees.length && !!branchData.countryID && !errors?.countryID) fetchLincensees();
        fetchUserRoles();
    }, [
        fetchCountries,
        fetchRegions,
        fetchLincensees,
        fetchCities,
        fetchUserRoles,
        branchData,
        licensees.length,
        errors?.countryID,
        cities.length,
        regions.length,
    ]);

    const handleBlur = (email: string, index: number) => {
        if (isEmail(email)) {
            setFetchingUser(true);
            getUserDetails(email)
                .then((res) => {
                    const result = res?.data?.data?.[0];
                    const users = branchData.users;
                    users[index].id = result.mail;
                    users[index].name = result.displayName;
                    users[index].phoneNumber = result.mobilePhone;
                    setBranchData({
                        ...branchData,
                        users,
                    });
                })
                .catch(() => {
                    let userErrors = errors?.users;
                    const errorId = `${t("ERRORS.EMAIL_DONOT_EXIST")}`;
                    if (userErrors) {
                        const currentUserError = userErrors[index];
                        currentUserError.id = errorId;
                        userErrors[index] = currentUserError;
                    } else {
                        userErrors = [
                            {
                                id: errorId,
                                roleId: "",
                            },
                        ];
                    }
                    const users = branchData.users;
                    users[index].name = "";
                    users[index].phoneNumber = "";
                    setBranchData({
                        ...branchData,
                        users,
                    });
                    setErrors({ ...errors, users: userErrors });
                })
                .finally(() => setFetchingUser(false));
        } else {
            let userErrors = errors?.users;
            const errorId = `${t("ERRORS.VALID_EMAIL")}`;
            if (userErrors) {
                const currentUserError = userErrors[index];
                currentUserError.id = errorId;
                userErrors[index] = currentUserError;
            } else {
                userErrors = [
                    {
                        id: errorId,
                        roleId: "",
                    },
                ];
            }
            setErrors({ ...errors, users: userErrors });
        }
    };

    const handleChange = (
        name: string,
        value: string | boolean | Partial<User>[],
        index?: number,
    ) => {
        if (Number(index) >= 0) {
            const users = branchData.users;
            const selectedUser = users[Number(index)];
            if (name === "id") {
                selectedUser.id = value as string;
            }
            if (name === "roleId") {
                selectedUser.roleId = value as string;
            }
            users[Number(index)] = selectedUser;
            setBranchData({ ...branchData, users: [...users] });
            setErrors({
                ...errors,
                users: users.map(() => ({ id: "", roleId: "" })),
                invalidUsers: "",
            });
        } else {
            if (name === "is24HourOpen") {
                setErrors({ ...errors, startTime: "", endTime: "" });
            } else if (errors?.[name]) {
                setErrors({ ...errors, [name]: "" });
            }

            if (name === "is24HourOpen" && value) {
                setBranchData({
                    ...branchData,
                    [name]: Boolean(value),
                    startTime: "00:00:00",
                    endTime: "00:00:00",
                });
            } else if (name === "countryID") {
                setRegions([]);
                setCities([]);
                setLicensees([]);
                setBranchData({
                    ...branchData,
                    [name]: String(value),
                    regionID: "",
                    cityID: "",
                });
                setErrors({
                    ...errors,
                    countryID: "",
                });
            } else if (name === "regionID") {
                setCities([]);
                setBranchData({
                    ...branchData,
                    [name]: String(value),
                    cityID: "",
                });
            } else setBranchData({ ...branchData, [name]: value });
        }
    };

    const handleCancel = () => {
        if (isDirty) {
            triggerShowModal(true, ModalType.CANCEL);
            setActions({
                done: () => {
                    setBranchData({ ...initialFormData, users: [...initialFormData.users] });
                    setErrors(undefined);
                    navigate(RoutePaths.ViewBranches);
                    triggerShowModal(false, ModalType.CANCEL);
                    setIsDirty(false);
                },
                cancel: () => {
                    triggerShowModal(false, ModalType.CANCEL);
                },
            });
        } else navigate(RoutePaths.ViewBranches);
    };

    const handleCreateBranch = () => {
        const dataToSubmit = {
            ...branchData,
            users: branchData.users.filter((user) => user.id && user.roleId),
            branchType: Number(branchData.branchType),
            startTime: getTimeInSeconds(String(branchData.startTime)),
            endTime: getTimeInSeconds(String(branchData.endTime)),
        };
        createBranch(dataToSubmit)
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                setBranchData({ ...initialFormData, users: [...initialFormData.users] });
                navigate(RoutePaths.ViewBranches);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleUpdateBranch = () => {
        const dataToSubmit = {
            ...branchData,
            users: branchData.users.filter((user) => user.id && user.roleId),
            branchType: Number(branchData.branchType),
            startTime: getTimeInSeconds(String(branchData.startTime)),
            endTime: getTimeInSeconds(String(branchData.endTime)),
        };
        updateBranch(dataToSubmit)
            .then(() => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                setBranchData({ ...initialFormData, users: [...initialFormData.users] });
                navigate(RoutePaths.ViewBranches);
            })
            .catch(() => {
                triggerShowModal(false, ModalType.SUBMIT);
            });
    };

    const handleSubmit = () => {
        const {
            name,
            code,
            branchType,
            countryID,
            cityID,
            address,
            pinCode,
            licenseeID,
            is24HourOpen,
            startTime,
            endTime,
            users,
        } = branchData;

        const baseDataValid =
            name && code && branchType && countryID && cityID && address && pinCode && licenseeID;
        const isStartTimeValid = getTimeInSeconds(String(startTime)) > 0;
        const isEndTimeValid = getTimeInSeconds(String(endTime)) > 0;
        const timingsValid = is24HourOpen ? true : isStartTimeValid && isEndTimeValid;

        const validUsers = users.filter((user) => user.id && user.roleId);
        const nonEmptyUsers = users.filter((user) => user.id || user.roleId);
        const userIdCounts = validUsers
            ?.map((user) => user.id)
            ?.reduce(
                (allUsers, user) => {
                    const currentCount = allUsers[String(user)];
                    if (currentCount) allUsers[String(user)] = currentCount + 1;
                    else allUsers[String(user)] = 1;
                    return allUsers;
                },
                {} as {
                    [key: string]: number;
                },
            );

        const duplicateUsers = Object.keys(userIdCounts)?.filter((key) => userIdCounts[key] > 1);

        const usersAreValid = validUsers.length && validUsers.length === nonEmptyUsers.length;

        const isDataValid =
            baseDataValid && timingsValid && usersAreValid && !duplicateUsers.length;
        const errorObj = errors || { users: [] };
        const emailError = Array.isArray(errorObj?.users)
            ? errorObj?.users?.filter((user) => user.id !== "")
            : [];
        if (isDataValid && !emailError?.length) {
            triggerShowModal(true, ModalType.SUBMIT);
            setActions({
                done: () => (branch ? handleUpdateBranch() : handleCreateBranch()),
                cancel: () => triggerShowModal(false, ModalType.SUBMIT),
            });
        } else {
            const currentErrors: Partial<BranchErrors> = {};
            if (!name) {
                currentErrors.name = "Branch Name is required";
            }
            if (!code) {
                currentErrors.code = "Branch Code is required";
            }
            if (!branchType) {
                currentErrors.branchType = "Branch Type is required";
            }
            if (!countryID) {
                currentErrors.countryID = "Branch Country is required";
            }
            if (!cityID) {
                currentErrors.cityID = "Branch City is required";
            }
            if (!address) {
                currentErrors.address = "Branch Address is required";
            }
            if (!pinCode) {
                currentErrors.pinCode = "Branch Pincode is required";
            }
            if (!licenseeID) {
                currentErrors.licenseeID = "Licensee is required";
            }
            if (!timingsValid) {
                if (!isStartTimeValid) currentErrors.startTime = "Start Time is required";
                if (!isEndTimeValid) currentErrors.endTime = "End Time is required";
            }
            if (!usersAreValid) {
                const userErrors: UserErrors[] = [];
                users.forEach((user) => {
                    const userError = {
                        id: "",
                        roleId: "",
                    };
                    if (!user.id) {
                        userError.id = `${t("ERRORS.REQUIRED", {
                            name: "User  ID",
                        })}`;
                    }
                    if (!user.roleId) {
                        userError.roleId = `${t("ERRORS.REQUIRED", {
                            name: "User Role",
                        })}`;
                    }
                    userErrors.push(userError);
                });
                currentErrors.users = userErrors;
            }
            if (duplicateUsers.length) {
                const userErrors: UserErrors[] = [];
                users.forEach((user) => {
                    const userError = {
                        id: "",
                        roleId: "",
                    };
                    if (duplicateUsers?.includes(String(user.id))) {
                        userError.id = "Duplicate users are not allowed";
                    }
                    userErrors.push(userError);
                });
                currentErrors.users = userErrors;
            }
            setErrors({ ...errors, ...currentErrors });
        }
    };
    // const manageAccessHandler = (email: string) => {
    //     if (email) {
    //         getPasskey(email)
    //             .then((res) => {
    //                 setViewPassKeyModal(true);
    //                 setPasseKeyModalObj({ email: email, passKey: res.data.data });
    //             })
    //             .catch(() => setPasseKeyModalObj({ email: "", passKey: "" }));
    //     }
    // };

    return (
        <FormWrapper
            heading={
                window.location.href.includes("edit")
                    ? "Edit a branch details"
                    : "Fill in details to add a branch"
            }
            onCancelClick={handleCancel}
            onSubmitClick={handleSubmit}
            submitDisabled={!isDirty || featchingUser}
        >
            <BranchForm
                data={branchData}
                onChange={handleChange}
                errors={errors}
                isEdit={!!branch}
                countries={countries}
                regions={regions}
                cities={cities}
                licensees={licensees}
                onBlur={handleBlur}
                loadingUser={featchingUser}
                roles={roles}
                // onClickManageLicense={() => setOpenLicenseModal(true)}
                onClickManageLicense={console.log}
                // manageAccessHandler={manageAccessHandler}
                manageAccessHandler={console.log}
            />
            {/* <ManageLicense
                key={`manage_license_${openLicenseModal}`}
                branch={branch}
                isOpen={openLicenseModal}
                onClose={() => setOpenLicenseModal(false)}
        /> */}

            {/* <BootstrapModal
                header='View PassKey'
                open={viewPassKeyModal}
                onClose={() => setViewPassKeyModal(false)}
            >
                <Container>
                    <Row className='mt-4'>
                        <TextInput
                            type='text'
                            label='User ID'
                            value={passKeyModalObj.email}
                            disabled
                            placeholder=''
                        />
                    </Row>
                    <Row className='add-branch__viewPassKeyField'>
                        <TextInput
                            type='text'
                            label='Passkey'
                            value={passKeyModalObj.passKey}
                            disabled
                            placeholder=''
                        />
                        <img
                            className='add-branch__copyIcon'
                            src={Icons.copyIcon}
                            onClick={() => navigator.clipboard.writeText(passKeyModalObj.passKey)}
                        />
                    </Row>
                    <Row className='add-branch__viewPasskeyBtns'>
                        <LoadingButton
                            variant='add'
                            label='Done'
                            onClick={() => setViewPassKeyModal(false)}
                        />
                    </Row>
                </Container>
            </BootstrapModal> */}
        </FormWrapper>
    );
};

export default BranchContainer;
