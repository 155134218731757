import { FC } from "react";

import Icons from "@app/assets/Icons";

import "./index.scss";

interface CheckboxProps {
    label: string;
    checked: boolean;
    onClick: () => void;
    className?: string;
}

const Checkbox: FC<CheckboxProps> = ({ label, onClick, checked, className }) => (
    <div className={`checkbox ${className}`} onClick={onClick}>
        <button className='checkbox__icon'>
            {checked && (
                <span className='checkbox__checked'>
                    <img className='checkbox__tick' src={Icons.CheckIcon} />
                </span>
            )}
        </button>
        <p className='checkbox__label'>{label}</p>
    </div>
);

export default Checkbox;
