import { ChangeEvent, FC, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Icons from "@app/assets/Icons";
import Avatar from "@app/components/Avatar";
import LoadingButton from "@app/components/Button";
import Loader from "@app/components/Loader";
import BootstrapModal from "@app/components/Modal";
import Checkbox from "@app/components/inputs/Checkbox";
import TextInput from "@app/components/inputs/Text";
import { SelectSalesChannelErrors } from "@app/types/Common.types";
import { SalesChannel } from "@app/types/SelectSalesChannel.types";
import "./index.scss";
import { AppContext } from "@app/utils/contexts/App.context";

interface HeaderRowProps {
    heading: string;
    headerName: string[];
    checked: string[];
    salesChannel: SalesChannel[];
    salesChannelValues: Record<string, string>;
    onClickHeaderCheckBox: () => void;
    onClickRowCheckBox: (_c: SalesChannel) => void;
    OnChangeInputFields: (_e: ChangeEvent<HTMLInputElement>) => void;
    OnClickCancel: () => void;
    OnClickSubmit: () => void;
    OnClickBack: () => void;
    submitDisabled?: boolean;
    error?: SelectSalesChannelErrors;
    hideSubmit: boolean;
}

const HeaderRowWrapper: FC<HeaderRowProps> = ({
    checked,
    salesChannel,
    onClickHeaderCheckBox,
    headerName,
    onClickRowCheckBox,
    salesChannelValues,
    OnChangeInputFields,
    OnClickCancel,
    OnClickBack,
    submitDisabled,
    OnClickSubmit,
    error,
    hideSubmit,
}) => {
    const { isLoading, showLoader } = useContext(AppContext);
    const location = useLocation();
    return (
        <Container fluid className='selectSalesChannel'>
            <Row>
                {error?.atLeastOneSelected && (
                    <div className='selectSalesChannel__error'>
                        <img src={Icons.ErrorGreyIcon} /> <span>{error.atLeastOneSelected}</span>
                    </div>
                )}
            </Row>
            <Row className='selectSalesChannel__header'>
                <Col
                    key='check-box'
                    className={"selectSalesChannel__header__content selectSalesChannel__column"}
                    md={1}
                    lg={1}
                >
                    <Checkbox
                        label={""}
                        checked={checked.length > 0 && checked.length === salesChannel.length}
                        onClick={() => onClickHeaderCheckBox()}
                    />
                </Col>
                <Col key='CHANNEL' className={"selectSalesChannel__header__content"} md={7} lg={7}>
                    {headerName[0]}
                </Col>
                <Col
                    key='Weightage'
                    className={"selectSalesChannel__header__content"}
                    md={4}
                    lg={4}
                >
                    {headerName[1]}
                </Col>
            </Row>
            <div className='selectSalesChannel__page'>
                {salesChannel?.map((data, index) => (
                    <Row
                        className={`selectSalesChannel__rowWrap ${
                            index % 2 === 0 ? "selectSalesChannel__rowWrap__grey-bg" : ""
                        }`}
                        key={data.id}
                    >
                        <Col className='selectSalesChannel__column' md={1} lg={1}>
                            <Checkbox
                                label={""}
                                checked={checked.includes(data.id)}
                                onClick={() => onClickRowCheckBox(data)}
                            />
                        </Col>
                        <Col key={index} md={7} lg={7}>
                            <Avatar label={data.name} reverse={true} />
                        </Col>
                        <Col md={2} lg={2}>
                            <TextInput
                                key={data.id}
                                placeholder={"Enter Time"}
                                value={salesChannelValues[data.id]}
                                type='number'
                                onChange={OnChangeInputFields}
                                name={data.id}
                                disabled={!checked.includes(data.id)}
                                error={
                                    error?.Weightage.includes(data.id)
                                        ? "Service Goal Time is required"
                                        : ""
                                }
                            />
                        </Col>
                    </Row>
                ))}
            </div>

            <Row className='selectSalesChannel__button-wrapper'>
                <Col sm={2}>
                    {location.state?.NavigateViaSelectProduct && (
                        <LoadingButton
                            btnClass='activate-deactivate__modal-btn-wrapper--continue w-170 label-black'
                            label={"Back"}
                            variant='back'
                            onClick={() => OnClickBack()}
                        />
                    )}
                </Col>
                <Col sm={6} />
                <Col sm={2}>
                    <LoadingButton
                        btnClass='salechannel__modal-btn-wrapper--continue w-170 label-black'
                        label={"Cancel"}
                        variant='cancel'
                        disabled={submitDisabled || isLoading}
                        onClick={() => OnClickCancel()}
                    />
                </Col>
                {!hideSubmit && (
                    <Col sm={2}>
                        <LoadingButton
                            btnClass='btn_darkRed w-170 label-white'
                            label={"Submit"}
                            variant='submit'
                            disabled={submitDisabled || isLoading}
                            onClick={() => OnClickSubmit()}
                        />
                    </Col>
                )}
            </Row>
            {isLoading && (
                <BootstrapModal
                    contentClassName='form-wrapper__modal-body'
                    backdropClassName='form-wrapper__modal'
                    open
                    size='sm'
                >
                    {showLoader && <Loader />}
                </BootstrapModal>
            )}
        </Container>
    );
};

export default HeaderRowWrapper;
