import { t } from "i18next";
import { ChangeEvent, FC, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Icons from "@app/assets/Icons";
import LoadingButton from "@app/components/Button";
import Loader from "@app/components/Loader";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import Toggle from "@app/components/inputs/Toggle";
import { Branch, BranchType } from "@app/types/Branch.types";
import { BranchErrors } from "@app/types/Common.types";
import { City, Country, Region } from "@app/types/Countries.types";
import { Licensee } from "@app/types/Licensee";
import { Role, User } from "@app/types/User.type";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";

import "./index.scss";

interface BranchFormProps {
    data: Branch;
    onChange: (_name: string, _value: string | boolean | Partial<User>[], _index?: number) => void;
    countries: Country[];
    regions: Region[];
    cities: City[];
    roles: Role[];
    licensees: Licensee[];
    errors?: Partial<BranchErrors>;
    isEdit?: boolean;
    onBlur: (_value: string, _index: number) => void;
    loadingUser: boolean;
    manageAccessHandler: (_id: string) => void;
    onClickManageLicense: () => void;
}

const BranchForm: FC<BranchFormProps> = ({
    data,
    countries,
    regions,
    cities,
    errors,
    onChange,
    onBlur,
    loadingUser,
    licensees,
    roles,
    isEdit,
    manageAccessHandler,
    onClickManageLicense,
}) => {
    const { setIsDirty } = useContext(FormContext);
    const { userPermissions } = useContext(AppContext);

    const branchTypeOptions = Object.values(BranchType)
        ?.map((key, index, array) => {
            if (typeof key === "string") {
                return {
                    value: String(array[array.length / 2 + index]),
                    label: t(`ADD.BRANCH.TYPE.${key.toUpperCase()}`),
                };
            }
        })
        .filter(Boolean) as { value: string; label: string }[];

    const countryOptions = [
        { value: "", label: "Select Branch Country" },
        ...(countries?.map((country) => ({ value: country.id, label: country.name })) ?? []),
    ];

    const regionOptions = [
        { value: "", label: isEdit && !data.regionID ? "" : "Select Branch Region" },
        ...(regions?.map((region) => ({ value: region.id, label: region.name })) ?? []),
    ];

    const cityOptions = [
        { value: "", label: "Select Branch City" },
        ...(cities?.map((city) => ({ value: city.id, label: city.name })) ?? []),
    ];

    const licenseeOptions = [
        { value: "", label: "Select Licensee" },
        ...(licensees?.map((licensee) => ({ value: licensee.id, label: licensee.name })) ?? []),
    ];

    const roleOptions = [
        { value: "", label: "Select User Role" },
        ...(roles?.map((role) => ({ value: role.id, label: role.name, key: role.id })) ?? []),
    ];

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, index?: number) => {
        setIsDirty(true);
        const { name, value } = e.target;
        onChange(name, value, index);
    };

    const handleBlur = (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const { value } = e.target;
        onBlur(value, index);
    };

    const handleAdd = () => {
        setIsDirty(true);
        onChange("users", [
            ...data.users,
            {
                id: "",
                name: "",
                phoneNumber: "",
                roleId: "",
            },
        ]);
    };

    const handleRemove = (index: number) => {
        setIsDirty(true);
        const filteredUsers = data.users.filter((_, idx) => idx !== index);
        onChange("users", filteredUsers);
    };

    return (
        <Container fluid className='py-4 px-5'>
            <Row className='mb-4'>
                <Col xs={12}>
                    <TextInput
                        label='Name'
                        placeholder='Enter Branch Name'
                        value={data.name || ""}
                        onChange={handleChange}
                        name='name'
                        error={errors?.name}
                        disabled={isEdit}
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs={6}>
                    <TextInput
                        label='ID'
                        placeholder='Enter Branch ID'
                        value={data.code || ""}
                        onChange={handleChange}
                        name='code'
                        error={errors?.code}
                        disabled={isEdit}
                    />
                </Col>
                <Col xs={6}>
                    <SelectInput
                        label='Type'
                        value={String(data.branchType)}
                        onChange={handleChange}
                        name='branchType'
                        options={branchTypeOptions}
                        error={errors?.branchType}
                    />
                </Col>
            </Row>
            <Row className='mb-4 align-items-center pt-4'>
                <Col xs={4} className='py-2'>
                    <Form.Group className='d-flex align-items-end gap-4'>
                        <Form.Label className='text-input__label'>Working Hours</Form.Label>
                        <Toggle
                            isActive={data?.is24HourOpen}
                            onToggle={() => {
                                setIsDirty(true);
                                onChange("is24HourOpen", !data.is24HourOpen);
                            }}
                            variant='on'
                        />
                        <Form.Label className='text-input__label'>24 Hours</Form.Label>
                    </Form.Group>
                </Col>
                {!data.is24HourOpen && (
                    <>
                        <Col xs={4}>
                            <TextInput
                                label='Opening Time'
                                placeholder='00:00:00'
                                value={String(data.startTime ?? "00:00:00")}
                                onChange={handleChange}
                                name='startTime'
                                error={errors?.startTime}
                                type='time'
                                step='2'
                            />
                        </Col>
                        <Col xs={4}>
                            <TextInput
                                label='Closing Time'
                                placeholder='00:00:00'
                                value={String(data.endTime)}
                                onChange={handleChange}
                                name='endTime'
                                error={errors?.endTime}
                                type='time'
                                step='2'
                            />
                        </Col>
                    </>
                )}
            </Row>
            <Row className='mb-4'>
                <Col xs={4}>
                    <SelectInput
                        label='Country'
                        value={String(data.countryID)}
                        onChange={handleChange}
                        name='countryID'
                        options={countryOptions}
                        error={errors?.countryID}
                        disabled={isEdit}
                    />
                </Col>
                <Col xs={4}>
                    <SelectInput
                        label='Region (optional)'
                        value={data.regionID ? data.regionID : ""}
                        onChange={handleChange}
                        name='regionID'
                        options={regionOptions}
                        error={errors?.regionID}
                        disabled={isEdit}
                    />
                </Col>
                <Col xs={4}>
                    <SelectInput
                        label='City'
                        value={String(data.cityID)}
                        onChange={handleChange}
                        name='cityID'
                        options={cityOptions}
                        error={errors?.cityID}
                        disabled={isEdit}
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs={8}>
                    <TextInput
                        label='Address'
                        placeholder='Enter Branch Address'
                        value={data.address}
                        onChange={handleChange}
                        name='address'
                        error={errors?.address}
                        disabled={isEdit}
                    />
                </Col>
                <Col xs={4}>
                    <TextInput
                        label='Pincode'
                        placeholder='Enter Branch Pincode'
                        value={data.pinCode || ""}
                        onChange={handleChange}
                        name='pinCode'
                        error={errors?.pinCode}
                        type='number'
                        disabled={isEdit}
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs={8}>
                    <SelectInput
                        label='Licensee'
                        value={String(data?.licenseeID ?? "")}
                        onChange={handleChange}
                        name='licenseeID'
                        options={licenseeOptions}
                        error={errors?.licenseeID}
                    />
                </Col>
                <Col xs={3} className='mt-4 pt-1 px-4'>
                    <LoadingButton
                        onClick={onClickManageLicense}
                        btnClass='add-branch__loading-button add-branch__manageAccessBtn'
                        variant='upload'
                        label='Manage License'
                        fullWidth
                        // disabled={!data?.licenseeID || !data.id}
                        disabled
                    />
                </Col>
            </Row>
            <Row className='mb-4'>
                <Container fluid>
                    {data?.users?.map((user, index, allUsers) => (
                        <Row key={`user_${index}`} className='align-items-start mb-3'>
                            <Col xs={8}>
                                <TextInput
                                    label={`${t("ADD.USER.EMAIL.LABEL")}`}
                                    placeholder={t("ADD.USER.EMAIL.PLACEHOLDER")}
                                    name='id'
                                    onChange={(e) => handleChange(e, index)}
                                    onBlur={(e) => handleBlur(e, index)}
                                    value={user?.id ?? ""}
                                    error={errors?.users && errors?.users[index]?.id}
                                />
                            </Col>
                            <Col xs={4} className='mt-4'>
                                {loadingUser ? (
                                    <Loader size='sm' />
                                ) : (
                                    <>
                                        <div className='role-form__userInfo'>{user.name}</div>
                                        <div className='role-form__userInfo'>
                                            {user.phoneNumber}
                                        </div>
                                    </>
                                )}
                            </Col>
                            <Col xs={8} className='mr-1 mt-1'>
                                <SelectInput
                                    label='User Role'
                                    value={String(user.roleId)}
                                    onChange={(e) => handleChange(e, index)}
                                    name='roleId'
                                    options={roleOptions}
                                    error={errors?.users && errors?.users[index]?.roleId}
                                />
                            </Col>
                            <Col xs={2} className='pt-4 mt-2'>
                                <LoadingButton
                                    btnClass='add-branch__manageAccessBtn add-branch__loading-button'
                                    variant='upload'
                                    label='Manage Access'
                                    fullWidth
                                    onClick={() => manageAccessHandler(user.id || "")}
                                    // disabled={!user.id}
                                    disabled
                                />
                            </Col>
                            <Col xs={2} className='pt-4 mt-2'>
                                {index !== allUsers.length - 1 ? (
                                    <LoadingButton
                                        onClick={() => handleRemove(index)}
                                        icon={
                                            <img
                                                src={Icons.DustbinIcon}
                                                className='add-product__dustbin-icon'
                                            />
                                        }
                                        btnClass='add-branch__loading-button add-branch__manageAccessBtn'
                                        variant='upload'
                                        label={t("COMMON.DELETE")}
                                        fullWidth
                                    />
                                ) : (
                                    <LoadingButton
                                        onClick={() => {
                                            handleAdd();
                                        }}
                                        icon={
                                            <img
                                                src={Icons.PlusIcon}
                                                className='add-product__dustbin-icon'
                                            />
                                        }
                                        btnClass='add-branch__loading-button add-branch__manageAccessBtn'
                                        variant='upload'
                                        label={t("COMMON.ADD", {
                                            name: "User",
                                        })}
                                        fullWidth
                                    />
                                )}
                            </Col>
                        </Row>
                    ))}
                    {errors?.invalidUsers && (
                        <Form.Text className='select-input__error'>
                            <img src={Icons.ErrorGreyIcon} /> <span>{errors.invalidUsers}</span>
                        </Form.Text>
                    )}
                </Container>
            </Row>
            {isEdit && userPermissions?.Deactivate_Branch && (
                <Row className='mt-4'>
                    <Col>
                        <Form.Group className='d-flex align-items-end gap-4'>
                            <Form.Label className='text-input__label'>
                                {t("MACHINE.CREATE.STATUS.LABEL")}
                            </Form.Label>
                            <Toggle
                                isActive={data?.isActive}
                                onToggle={() => {
                                    setIsDirty(true);
                                    onChange("isActive", !data.isActive);
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </Container>
    );
};
export default BranchForm;
