import { ChangeEvent, FC } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import Icons from "@app/assets/Icons";
import {
    LooseValue as DatePickerLooseValue,
    Value as DatePickerValue,
} from "@app/types/DatePicker.types";
import {
    LooseValue as TimePickerLooseValue,
    Value as TimePickerValue,
} from "@app/types/TimePicker.types";

import "./index.scss";

interface TextInputProps {
    label?: string;
    placeholder: string;
    value: string | number | null;
    onChange?: (_e: ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    error?: string;
    type?: "text" | "number" | "time" | "date" | "duration";
    disabled?: boolean;
    onBlur?: (_e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown?: (_e: React.KeyboardEvent<HTMLInputElement>) => void;
    max?: string;
    min?: string;
    step?: string;
    twoDigitAllowed?: boolean;
    decimalAllowed?: boolean;
    hideClockIcon?: boolean;
}

const TextInput: FC<TextInputProps> = ({
    label,
    placeholder,
    value,
    onChange,
    name,
    error,
    type = "text",
    disabled,
    onBlur,
    onKeyDown,
    max,
    min,
    step,
    twoDigitAllowed,
    decimalAllowed,
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let re = twoDigitAllowed ? /^\d{0,2}$/ : /^[0-9\b]+$/;
        if (decimalAllowed) {
            re = /^[0-9.\b]+$/;
        }
        if (type === "number" && (e.target.value === "" || re.test(e.target.value))) {
            onChange?.(e);
        } else if (type !== "number") onChange?.(e);
    };

    const getInputType = () => {
        switch (type) {
            case "number":
            case "text":
                return "text";
            case "time":
                return "time";
            case "duration":
                return "duration";
            case "date":
                return "date";
            default:
                return "text";
        }
    };

    const handleDurationChange = (value: TimePickerValue) => {
        onChange?.({
            target: { name: String(name), value: String(value) },
        } as ChangeEvent<HTMLInputElement>);
    };

    const handleDateChange = (value: DatePickerValue) => {
        onChange?.({
            target: { name: String(name), value: String(value) },
        } as ChangeEvent<HTMLInputElement>);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value.length) {
            onBlur?.(event);
        }
        return;
    };

    return (
        <Form.Group className='text-input'>
            {label && <Form.Label className='text-input__label'>{label}</Form.Label>}
            {type === "duration" ? (
                <TimePicker
                    onChange={handleDurationChange}
                    value={(value as TimePickerLooseValue) ?? "00:00:00"}
                    clearIcon={null}
                    disableClock
                    format={"HH:mm:ss"}
                    maxDetail='second'
                    className='text-input__input'
                    disabled={disabled}
                    onBlur={handleBlur}
                    onKeyDown={onKeyDown}
                    name={name}
                />
            ) : type === "time" ? (
                <TimePicker
                    onChange={handleDurationChange}
                    value={(value as TimePickerLooseValue) ?? "00:00:00"}
                    clearIcon={null}
                    format={"HH:mm:ss"}
                    maxDetail='second'
                    className='text-input__input'
                    disabled={disabled}
                    onBlur={handleBlur}
                    onKeyDown={onKeyDown}
                    name={name}
                    clockIcon={null}
                />
            ) : type === "date" ? (
                <DatePicker
                    onChange={handleDateChange}
                    value={value as DatePickerLooseValue | null}
                    clearIcon={null}
                    className='text-input__input'
                    disabled={disabled}
                    onBlur={handleBlur}
                    onKeyDown={onKeyDown}
                    name={name}
                    minDate={new Date()}
                    format='dd/MM/y'
                    dayPlaceholder='dd'
                    monthPlaceholder='mm'
                    yearPlaceholder='yyyy'
                />
            ) : (
                <Form.Control
                    type={getInputType()}
                    placeholder={placeholder}
                    value={value ?? ""}
                    onChange={handleChange}
                    name={name}
                    className='text-input__input'
                    disabled={disabled}
                    autoComplete='off'
                    onBlur={handleBlur}
                    onKeyDown={onKeyDown}
                    max={max}
                    min={min}
                    step={step}
                />
            )}
            {error && (
                <Form.Text className='text-input__error'>
                    <img src={Icons.ErrorGreyIcon} /> <span>{error}</span>
                </Form.Text>
            )}
        </Form.Group>
    );
};

export default TextInput;
