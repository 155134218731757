import MyBranchesPage from "@app/pages/licensee/pages/MyBranch/index.page";
import SelectMachinesPage from "@app/pages/licensee/pages/SelectMachines/index.page";
import SelectProductsPage from "@app/pages/licensee/pages/SelectProducts/index.page";
import SelectSalesChannelPage from "@app/pages/licensee/pages/SelectSalesChannel/index.page";
import { RouteNames } from "@app/routes/index";
import { SidebarOptions, UserPermissions } from "@app/types/Common.types";
import { Branches } from "@app/types/Service.type";

const SidebarData = (
    branchData?: Branches,
    userPermissions?: UserPermissions,
): SidebarOptions[] => [
    {
        path: RouteNames.Branches,
        label: "Branches",
        primaryText: "My",
        secondaryText: "Branches",
        element: <MyBranchesPage />,
        disableSideLink: !branchData && userPermissions?.Read_Branch,
        authorized: userPermissions?.Read_Branch,
    },
    {
        path: "",
        label: String(branchData?.name || ""),
        primaryText: "My",
        secondaryText: "Branches",
        element: <></>,
        staticLink: true,
        disableSideLink: !branchData && userPermissions?.Read_Branch,
        authorized: userPermissions?.Read_Branch,
    },
    {
        path: RouteNames.Machines,
        label: "Select Machines",
        primaryText: "Select",
        secondaryText: "Machines",
        element: <SelectMachinesPage />,
        disableSideLink:
            !branchData && !(userPermissions && userPermissions["Create_Kitchen Setup"]),
        authorized: userPermissions && userPermissions["Create_Kitchen Setup"],
    },
    {
        path: RouteNames.Products,
        label: "Select Products",
        primaryText: "Select",
        secondaryText: "Products",
        element: <SelectProductsPage />,
        disableSideLink:
            !branchData && !(userPermissions && userPermissions["Create_Kitchen Setup"]),
        authorized: userPermissions && userPermissions["Create_Kitchen Setup"],
    },
    {
        path: RouteNames.SalesChannels,
        label: "Select Sales Channels",
        primaryText: "Select",
        secondaryText: "Sales Channels",
        element: <SelectSalesChannelPage />,
        disableSideLink:
            !branchData && !(userPermissions && userPermissions["Create_Kitchen Setup"]),
        authorized: userPermissions && userPermissions["Create_Kitchen Setup"],
    },
];

export default SidebarData;
