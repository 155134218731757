import { t } from "i18next";
import { ChangeEvent, FC } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import Checkbox from "@app/components/inputs/Checkbox";
import TextInput from "@app/components/inputs/Text";
import { SystemConfigurationErrors } from "@app/types/Common.types";
import { SystemConfigurationFormData } from "@app/types/SystemConfiguration";

interface SystemConfigurationFormProps {
    data: SystemConfigurationFormData;
    errors?: Partial<SystemConfigurationErrors>;
    onChange: (_name: string, _value: string | boolean) => void;
}

const SystemConfigurationForm: FC<SystemConfigurationFormProps> = ({ data, errors, onChange }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(name, value);
    };

    return (
        <Container fluid className='py-4 px-5'>
            <Row className='mb-5'>
                <Col xs={6}>
                    <TextInput
                        type='number'
                        label={`${t("SYSTEMCONFIGURATION.DELAYTIME")}`}
                        placeholder='Max'
                        name='delayTime'
                        onChange={handleChange}
                        value={data.delayTime}
                        error={errors?.name}
                        twoDigitAllowed={true}
                    />
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <Form.Group>
                        <Form.Label className='text-input__label mb-4'>Select Features</Form.Label>
                        <div className='d-flex'>
                            <Checkbox
                                checked={data.outOfQueue}
                                label='Out of Queue'
                                onClick={() => onChange("outOfQueue", !data.outOfQueue)}
                            />
                            <Checkbox
                                className='mx-5'
                                checked={data.wastage}
                                label='Wastage'
                                onClick={() => onChange("wastage", !data.wastage)}
                            />
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    );
};

export default SystemConfigurationForm;
