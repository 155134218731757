import { useMsal } from "@azure/msal-react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { RouteNames } from "@app/routes/index";

import type { FC, ReactElement } from "react";

import "./index.scss";

interface AvatarProps {
    label: string;
    customIcon?: ReactElement;
    labelStyleName?: string;
    onClick?: () => void;
    reverse?: boolean;
    customIconStyle?: string;
    variant?: "insidePopover" | "sideMenu" | "logout";
    email?: string;
}

const Avatar: FC<AvatarProps> = ({
    label,
    customIcon,
    labelStyleName,
    onClick,
    reverse,
    customIconStyle,
    variant,
    email,
}) => {
    const labelInitial = label
        ?.match(/\b(\w)/g)
        ?.join("")
        .slice(0, 2);
    const { instance } = useMsal();
    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: RouteNames.Home,
        });
        const selectedBranchValue = localStorage.getItem("SelectedBranch");
        localStorage.clear();
        if (selectedBranchValue !== null) {
            localStorage.setItem("SelectedBranch", selectedBranchValue);
        }
    };
    const popover = (
        <Popover className='custom_popover' id='popover-basic'>
            <Popover.Body>
                <Avatar
                    label={label}
                    labelStyleName='app-layout__label'
                    variant='insidePopover'
                    reverse
                />
                <div className='custom_popover__email'>{email}</div>
            </Popover.Body>
            <Popover.Header
                className='custom_popover__header pointer-cursor'
                onClick={handleLogout}
            >
                Log Out
            </Popover.Header>
        </Popover>
    );

    return (
        <div className={`avatar ${reverse ? "avatar--reverse" : ""}`} onClick={onClick}>
            {variant != "logout" ? (
                <p className={`avatar__label ${labelStyleName ?? ""}`}>{label}</p>
            ) : (
                ""
            )}
            {customIcon ??
                (variant === "logout" ? (
                    <OverlayTrigger trigger='click' placement='bottom' overlay={popover} rootClose>
                        <span
                            className={`avatar__icon avatar__icon-logout pointer-cursor
                            } ${customIconStyle ?? ""}`}
                        >
                            {labelInitial}
                        </span>
                    </OverlayTrigger>
                ) : (
                    <span
                        className={`avatar__icon ${
                            variant === "insidePopover"
                                ? "avatar__icon-logout"
                                : "avatar__icon-menu"
                        } 
                        } ${customIconStyle ?? ""}`}
                    >
                        {labelInitial}
                    </span>
                ))}
        </div>
    );
};

export default Avatar;
