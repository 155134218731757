import { t } from "i18next";
import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/esm/Accordion";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@app/components/Button";
import Loader from "@app/components/Loader";
import BootstrapModal from "@app/components/Modal";
import SelectInput from "@app/components/inputs/Select";
import TextInput from "@app/components/inputs/Text";
import MachineTable from "@app/containers/selectMachine/machineTable";
import TanjaraSection from "@app/containers/selectMachine/tanjara";
import { RoutePaths } from "@app/routes/index";
import { getCookingStations } from "@app/services/Endpoints/Item.API";
import {
    deleteMachine,
    getMachineMake,
    getMachineName,
    getSelectedMachines,
    postMachine,
} from "@app/services/Endpoints/SelectMachines.API";
import { Errors, ModalType } from "@app/types/Common.types";
import { machineListInterface, tanjaraItem } from "@app/types/SelectMachine.types";
import { AppContext } from "@app/utils/contexts/App.context";
import { FormContext } from "@app/utils/contexts/Form.context";
import "./index.scss";

const SelectMachinesPage: FC = () => {
    const { triggerShowModal, setActions, isDirty, setIsDirty } = useContext(FormContext);
    const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
    const [machineList, setMachineList] = useState<machineListInterface>({});
    const [machineMake, setMachineMake] = useState([]);
    const [machineName, setMachineName] = useState([]);
    const [cookingStations, setCookingStations] = useState<[]>([]);
    const [inlineError, setInlineError] = useState<string>("");
    const [addMachine, setAddMachine] = useState({
        make: "",
        name: "",
        displayName: "",
        serialNumber: "",
    });
    const [errors, setErrors] = useState<Partial<Errors>>();
    const navigate = useNavigate();
    // const [, setLoader] = useState<boolean>(false);
    // const [cookingStationsLoader, setCookingStationsLoader] = useState<boolean>(false);
    const currentStation = useRef<string | null>();
    const currentStationName = useRef<string | null>();
    const { isLoading, showLoader, userPermissions } = useContext(AppContext);

    const getMachineFn = (isCloseModal?: boolean) => {
        // setLoader(true);
        setInlineError("");
        if (isCloseModal) setShowSubmitModal(false);
        getSelectedMachines()
            .then((res) => {
                setMachineList(res.data.data?.stations);
            })
            .catch((err) => console.error(err));
        // .finally(() => setLoader(false));
    };

    useEffect(() => {
        // setCookingStationsLoader(true);
        getCookingStations()
            .then((res) => {
                const result = res.data.data;
                const cookingStation = result.filter(
                    (item: { stationType: string; stations: [] }) => item.stationType === "Cooking",
                );
                setCookingStations(cookingStation[0]?.stations);
            })
            .catch((err) => console.error(err));
        // .finally(() => setCookingStationsLoader(false));
        getMachineFn();

        getMachineMake().then((res) => {
            setMachineMake(
                res.data.data.map((machine: { id: string; name: string }) => ({
                    value: machine.id,
                    label: machine.name,
                })),
            );
        });
    }, []);
    const eventhandler = (_: AccordionEventKey, event: React.SyntheticEvent<HTMLDivElement>) => {
        if (event.currentTarget.getAttribute("aria-expanded") === "false") {
            currentStation.current = event.currentTarget.children[0].getAttribute("data-stationId");
            currentStationName.current = event.currentTarget.children[0].innerHTML;
        }
    };
    const addMachineHandler = () => {
        setInlineError("");
        setErrors({});
        setAddMachine({
            make: "",
            name: "",
            displayName: "",
            serialNumber: "",
        });
        setShowSubmitModal(true);
    };

    const validateMachineForm = () => {
        const { make, name, displayName, serialNumber } = addMachine;
        const currentErrors: Partial<Errors> = {};
        if (!make) {
            currentErrors.make = `${t("ERRORS.REQUIRED", {
                name: "Machine Make",
            })}`;
        }
        if (!name) {
            currentErrors.name = `${t("ERRORS.REQUIRED", {
                name: "Machine Name",
            })}`;
        }
        if (!displayName) {
            currentErrors.displayName = `${t("ERRORS.REQUIRED", {
                name: "Display Name",
            })}`;
        }
        if (!serialNumber) {
            currentErrors.serialNumber = `${t("ERRORS.REQUIRED", {
                name: "Machine Serial Number",
            })}`;
        }
        setErrors({ ...currentErrors });
        if (!Object.keys(currentErrors).length) {
            return true;
        }
        return false;
    };

    const machinePostObj = () => {
        const { displayName, serialNumber, name } = addMachine;
        return {
            stationId: currentStation.current || "",
            machineConfigId: name,
            displayName: displayName,
            serialNumber: serialNumber,
        };
    };
    const addMachineModal = (isCloseModal?: boolean) => {
        if (validateMachineForm()) {
            postMachine(machinePostObj()).then(() => {
                setAddMachine({
                    make: "",
                    name: "",
                    displayName: "",
                    serialNumber: "",
                });
                getMachineFn(isCloseModal);
            });
        }
    };
    const onSubmitModalClose = () => {
        setShowSubmitModal(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === "make") {
            setMachineName([]);
            getMachineName(value, currentStation.current || "").then((res) => {
                if (res?.data?.data) {
                    setMachineName(
                        res.data.data.map((machine: { id: string; machineName: string }) => ({
                            value: machine.id,
                            label: machine.machineName,
                        })),
                    );
                }
            });
        }
        setErrors({ ...errors, [name]: "" });
        setAddMachine({ ...addMachine, [name]: value });
    };

    const onDelete = (machineId: string) => {
        setIsDirty(true);
        triggerShowModal(true, ModalType.SUBMIT);
        setActions({
            done: () => {
                triggerShowModal(false, ModalType.SUBMIT);
                setIsDirty(false);
                deleteMachine(machineId).then((res) => {
                    if (res.data.succeeded) {
                        getMachineFn();
                    }
                });
            },
            cancel: () => {
                setIsDirty(false);
                triggerShowModal(false, ModalType.SUBMIT);
            },
        });

        // deleteMachine(machineId).then((res) => {
        //     if (res.data.succeeded) {
        //         getMachineFn();
        //     }
        // });
    };

    const updateTanjaraDetails = (id: string, obj: tanjaraItem) => {
        const tempObj = JSON.parse(JSON.stringify(machineList));
        if (tempObj[id]) {
            tempObj[id].trays = obj;
        } else {
            tempObj[id] = { trays: obj };
        }
        setMachineList({ ...tempObj });
    };

    const onNextClick = () => {
        setInlineError("");
        let noMachineAdded = true;
        const tanjaraMissingStations: string[] = [];
        cookingStations?.forEach((station: { stationId: string; displayName: string }) => {
            if (
                machineList[station.stationId] &&
                machineList[station.stationId]?.machines?.length
            ) {
                noMachineAdded = false;
                if (!machineList[station.stationId].trays.selectedTrays.length) {
                    tanjaraMissingStations.push(station.displayName);
                }
            }
        });

        if (noMachineAdded) setInlineError(`${t("ERRORS.ATLEAST_ONE_MACHINE")}`);
        else if (tanjaraMissingStations.length)
            setInlineError(
                `${t("ERRORS.TANJARA_REQUIRED", {
                    name: tanjaraMissingStations.join(),
                })}`,
            );
        else {
            if (isDirty) {
                triggerShowModal(true, ModalType.CANCEL);
                setActions({
                    done: () => {
                        triggerShowModal(false, ModalType.CANCEL);
                        setIsDirty(false);
                        navigate(`/${RoutePaths.SelectProducts}`, {
                            state: { NavigateViaMachine: "NavigateViaMachine" },
                        });
                    },
                    cancel: () => {
                        triggerShowModal(false, ModalType.CANCEL);
                    },
                });
            } else {
                navigate(`/${RoutePaths.SelectProducts}`, {
                    state: { NavigateViaMachine: "NavigateViaMachine" },
                });
            }
        }
    };
    return (
        <>
            {inlineError && <div className='selectMachine__inline-error'>{inlineError}</div>}
            <div className='selectMachine__wrapper'>
                <Accordion
                    className='selectMachine__accordian'
                    onSelect={(_, event) =>
                        eventhandler(_, event as React.SyntheticEvent<HTMLDivElement>)
                    }
                >
                    {
                        // cookingStationsLoader ? (
                        //     <Loader />
                        // ) :
                        cookingStations.map(
                            (station: { stationId: string; displayName: string }, index) => (
                                <Accordion.Item
                                    key={station.stationId}
                                    eventKey={String(index)}
                                    className='selectMachine__item'
                                >
                                    <Accordion.Header className='selectMachine__header'>
                                        <div
                                            className='selectMachine__header-label'
                                            data-stationId={station.stationId}
                                        >
                                            {station.displayName}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className='selectMachine__body'>
                                        {
                                            // loader ? (
                                            //     <Loader />
                                            // ) :
                                            machineList && (
                                                <>
                                                    <MachineTable
                                                        item={
                                                            machineList[station.stationId]?.machines
                                                        }
                                                        onClick={addMachineHandler}
                                                        onDelete={onDelete}
                                                    />
                                                    <TanjaraSection
                                                        item={machineList[station.stationId]?.trays}
                                                        handleChange={handleChange}
                                                        id={station.stationId}
                                                        updateTanjaraDetails={updateTanjaraDetails}
                                                    />
                                                </>
                                            )
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            ),
                        )
                    }
                </Accordion>

                {showSubmitModal && (
                    <BootstrapModal
                        header={`Add Machine to ${currentStationName.current} station`}
                        open={showSubmitModal}
                        onClose={onSubmitModalClose}
                        showFooter
                        doneCTALabel={"Submit"}
                        cancelCTALabel={"Submit and Add Another"}
                        onDone={() => {
                            addMachineModal();
                        }}
                        onCancel={() => {
                            addMachineModal(true);
                        }}
                        submitVariant='add'
                        cancelVariant='submit'
                    >
                        <Container>
                            <Row className='mb-4'>
                                <Col xs={12}>
                                    <SelectInput
                                        label='Select Machine Make'
                                        value={addMachine.make}
                                        onChange={handleChange}
                                        name='make'
                                        options={[
                                            { value: "", label: "Select Make" },
                                            ...machineMake,
                                        ]}
                                        error={errors?.make}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col xs={12}>
                                    <SelectInput
                                        label='Select Machine Name'
                                        value={addMachine.name}
                                        onChange={handleChange}
                                        name='name'
                                        options={[
                                            { value: "", label: "Select Name" },
                                            ...machineName,
                                        ]}
                                        error={errors?.name}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <TextInput
                                        type='number'
                                        label='Display Name'
                                        placeholder='Enter Name'
                                        value={addMachine.displayName}
                                        onChange={handleChange}
                                        name='displayName'
                                        error={errors?.displayName}
                                    />
                                </Col>
                                <Col xs={8}>
                                    <TextInput
                                        label='Machine Serial Number'
                                        placeholder='Enter Serial Number'
                                        value={addMachine.serialNumber}
                                        onChange={handleChange}
                                        name='serialNumber'
                                        error={errors?.serialNumber}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </BootstrapModal>
                )}
                {isLoading && (
                    <BootstrapModal
                        contentClassName='form-wrapper__modal-body'
                        backdropClassName='form-wrapper__modal'
                        open
                        size='sm'
                    >
                        {showLoader && <Loader />}
                    </BootstrapModal>
                )}
            </div>
            <Row className='selectMachine__nextBtn justify-content-end'>
                {userPermissions && userPermissions["Create_Kitchen Setup"] && (
                    <LoadingButton
                        label='Next'
                        onClick={onNextClick}
                        variant='submit'
                        disabled={isLoading}
                    />
                )}
            </Row>
        </>
    );
};

export default SelectMachinesPage;
